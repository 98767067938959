<template>
  <Page :title="$t('cookies.title')" :classes="['page--static', 'page--cookies']">
    <h5>{{$t('cookies.content.subtitle_1')}}</h5>
    <p>{{$t('cookies.content.paragraph_1_1')}}</p>
    <p>{{$t('cookies.content.paragraph_1_2')}}</p>
    <p>{{$t('cookies.content.paragraph_1_3')}}</p>
    <p>{{$t('cookies.content.paragraph_1_4')}}</p>

    <h5>{{$t('cookies.content.subtitle_2')}}</h5>
    <p>{{$t('cookies.content.paragraph_2_1')}}</p>
    <p>{{$t('cookies.content.paragraph_2_2')}}</p>
    <p>{{$t('cookies.content.paragraph_2_3')}}</p>
    <p>{{$t('cookies.content.paragraph_2_4')}}</p>
    <p>{{$t('cookies.content.paragraph_2_5')}}</p>

    <h5>{{$t('cookies.content.subtitle_3')}}</h5>
    <p>{{$t('cookies.content.paragraph_3_1')}}</p>
    <br>
    <p>{{$t('cookies.content.final_note')}}</p>
  </Page>
</template>

<script>
export default {
  name: 'CookiesPage',
};
</script>
